<template>
  <div class="passive-message">
    <img :alt="emptyStateAlt" src="@/assets/img/envelope-illustration-2.svg" />
    <p v-if="selectedMessagesLength === 0" class="passive-message__title">
      <translate>Select an email to read.</translate>
    </p>
    <p v-else class="passive-message__title">
      <strong>{{ selectedMessagesLength }}</strong>
      {{ selectedMessagesText }}
    </p>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { SPLIT_VERTICAL, SPLIT_HORIZONTAL } from '@/store/layout/layout';

  export default {
    name: 'PassiveMessage',
    data() {
      return {
        SPLIT_VERTICAL: SPLIT_VERTICAL,
        SPLIT_HORIZONTAL: SPLIT_HORIZONTAL,
      };
    },
    computed: {
      ...mapGetters(['selectedMessagesLength']),
      ...mapGetters('layout', ['activeSplit']),
      selectedMessagesText() {
        return this.$ngettext(
          ' message selected',
          ' messages selected',
          this.selectedMessagesLength
        );
      },
      emptyStateAlt() {
        return this.$gettext(
          'Hand drawn illustration of a hand holding up an envelope.'
        );
      },
    },
  };
</script>

<style src="./PassiveMessage.scss" lang="scss"></style>
