
























  import Vue from 'vue';
  import EmptyView from '@/components/EmptyView.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import emptyStateSearchImage from '@/assets/img/empty-state-search.svg';
  import emptyStateInboxImage from '@/assets/img/empty-state-inbox.svg';
  import emptyStateDraftsImage from '@/assets/img/empty-state-drafts.svg';
  import emptyStateSentImage from '@/assets/img/empty-state-sent.svg';
  import emptyStateTrashImage from '@/assets/img/empty-state-trash.svg';
  import emptyStateSpamImage from '@/assets/img/empty-state-spam.svg';
  import emptyStateFolderImage from '@/assets/img/empty-state-folder.svg';
  import { mapActions } from 'vuex';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export enum FolderIds {
    Inbox = 'inbox',
    Drafts = 'Drafts',
    Sent = 'Sent Messages',
    Trash = 'Deleted Messages',
    Junk = 'Junk',
  }

  export default Vue.extend({
    name: 'EmptyFolder',
    components: {
      EmptyView,
      Icon,
      SupportCenterLink,
    },
    props: {
      folderId: {
        type: String,
        required: false,
      },
      activeSearchQuery: {
        type: String,
        required: false,
      },
      activeFilters: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        image: null as string | null,
        content: null as string | null,
      };
    },
    created() {
      this.updateData();
    },
    computed: {
      isFolderIdValid(): boolean {
        return Boolean(this.folderId);
      },
    },
    methods: {
      ...mapActions('filters', ['setFilters']),
      updateData(): void {
        if (this.activeSearchQuery) this.setSearchView();
        else if (this.activeFilters) this.setFiltersView();
        else if (
          this.isFolderIdValid &&
          this.folderId.toLowerCase() === FolderIds.Inbox
        )
          this.setInboxView();
        else if (this.folderId === FolderIds.Drafts) this.setDraftsView();
        else if (this.folderId === FolderIds.Sent) this.setSentView();
        else if (this.folderId === FolderIds.Trash) this.setTrashView();
        else if (this.folderId === FolderIds.Junk) this.setJunkView();
        else this.setUserFolderView();
      },
      setSearchView(): void {
        this.image = emptyStateSearchImage;
        this.content = this.$gettext(
          'could not be found in any of your emails. Try using different keywords or check for typos. Or refine your search to a folder, recipient, date, or subject using a search operator.'
        );
      },
      setFiltersView(): void {
        this.image = emptyStateInboxImage;
        this.content = this.$gettext(
          'Your filters do not match any emails in this folder.'
        );
      },
      setInboxView(): void {
        this.image = emptyStateInboxImage;
        this.content = this.$gettext(
          'Sit back and relax, you have an empty <strong>Inbox</strong>.'
        );
      },
      setDraftsView(): void {
        this.image = emptyStateDraftsImage;
        this.content = this.$gettext(
          'Nice productivity skills! You have no draft emails to resume in your <strong>Drafts</strong> folder.'
        );
      },
      setSentView(): void {
        this.image = emptyStateSentImage;
        this.content = this.$gettext(
          'That’s a refreshing look, an empty <strong>Sent</strong> folder.'
        );
      },
      setTrashView(): void {
        this.image = emptyStateTrashImage;
        this.content = this.$gettext(
          'No emails in your <strong>Trash</strong>, does that feel good or what?'
        );
      },
      setJunkView(): void {
        this.image = emptyStateSpamImage;
        this.content = this.$gettext(
          'Wham, bam, no emails in your <strong>Spam</strong>.'
        );
      },
      setUserFolderView(): void {
        this.image = emptyStateFolderImage;
        this.content = this.$gettextInterpolate(
          this.$gettext(
            'Going for the uncluttered look? <strong>%{ folderId }</strong> is empty.'
          ),
          { folderId: this.isFolderIdValid ? this.folderId : '' }
        );
      },
      async clearFilters(): Promise<void> {
        const activeFilters = await this.setFilters({ filters: [] });
        const { page, ...query } = this.$route.query;
        this.$router.replace({
          ...this.$route,
          name: this.$route.name || undefined,
          query: { ...query, filter: activeFilters },
        });
        this.updateData();
      },
    },
  });
