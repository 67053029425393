<template>
  <component
    :is="tag"
    class="split-pane"
    :class="vertical ? 'split-pane--vertical' : 'split-pane--horizontal'"
  >
    <div
      class="split-pane__pane split-pane__pane--first"
      :style="firstPaneStyle"
      ref="firstPane"
    >
      <slot name="first" />
    </div>

    <div class="split-pane__separator" @mousedown="startResize" />

    <div
      class="split-pane__pane split-pane__pane--second"
      :style="secondPaneStyle"
      ref="secondPane"
    >
      <slot name="second" />
    </div>
  </component>
</template>

<script>
  import Icon from '@/components/Icon/Icon';
  import { getItem, setItem } from '@/lib/localStorage';

  export default {
    name: 'SplitPane',
    components: {
      Icon,
    },
    props: {
      localStorageKeyPrefix: {
        type: String,
        required: true,
      },
      tag: {
        type: String,
        default: 'div',
      },
      vertical: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        firstPaneRatio: null,
        firstPaneSize: null,
        secondPaneRatio: null,
        secondPaneSize: null,
      };
    },
    computed: {
      attribute() {
        return this.vertical ? 'width' : 'height';
      },
      localStorageKey() {
        return `sm-${this.localStorageKeyPrefix}-${
          this.vertical ? 'vertical' : 'horizontal'
        }`;
      },
      firstPaneStyle() {
        if (typeof this.firstPaneSize === 'number') {
          return {
            [this.attribute]: `${Math.floor(this.firstPaneSize)}px`,
          };
        } else if (typeof this.firstPaneRatio === 'number') {
          return {
            [this.attribute]: `${this.firstPaneRatio * 100}%`,
          };
        } else {
          return {};
        }
      },
      secondPaneStyle() {
        if (typeof this.secondPaneSize === 'number') {
          return {
            [this.attribute]: `${Math.ceil(this.secondPaneSize)}px`,
          };
        } else if (typeof this.secondPaneRatio === 'number') {
          return {
            [this.attribute]: `${this.secondPaneRatio * 100}%`,
          };
        } else {
          return {};
        }
      },
    },
    watch: {
      async vertical() {
        this.firstPaneSize = null;
        this.secondPaneSize = null;

        try {
          const { firstPaneRatio, secondPaneRatio } = await getItem(
            this.localStorageKey
          );
          this.firstPaneRatio = firstPaneRatio;
          this.secondPaneRatio = secondPaneRatio;
        } catch (err) {
          this.firstPaneRatio = null;
          this.secondPaneRatio = null;
        }

        this.$nextTick(this.setPanesRatio.bind(this));
      },
    },
    async mounted() {
      try {
        const { firstPaneRatio, secondPaneRatio } = await getItem(
          this.localStorageKey
        );
        this.firstPaneRatio = firstPaneRatio;
        this.secondPaneRatio = secondPaneRatio;
      } catch (err) {
        this.setPanesRatio();
      }
    },
    methods: {
      setPanesRatio() {
        const splitPaneSize = this.$el.getBoundingClientRect()[this.attribute];
        this.firstPaneRatio =
          this.$refs.firstPane.getBoundingClientRect()[this.attribute] /
          splitPaneSize;
        this.secondPaneRatio =
          this.$refs.secondPane.getBoundingClientRect()[this.attribute] /
          splitPaneSize;
      },
      resize(ev) {
        ev.preventDefault();
        window.requestAnimationFrame(() => {
          const delta = this.vertical
            ? this.posX - ev.clientX
            : this.posY - ev.clientY;
          this.firstPaneSize = this.firstPaneSize - delta;
          this.secondPaneSize = this.secondPaneSize + delta;
          this.posX = ev.clientX;
          this.posY = ev.clientY;
        });
      },
      startResize(ev) {
        document.addEventListener('mouseup', this.stopResize);
        document.addEventListener('mousemove', this.resize);
        this.posX = ev.clientX;
        this.posY = ev.clientY;
        this.firstPaneSize =
          this.$refs.firstPane.getBoundingClientRect()[this.attribute];
        this.secondPaneSize =
          this.$refs.secondPane.getBoundingClientRect()[this.attribute];
      },
      stopResize() {
        document.removeEventListener('mouseup', this.stopResize);
        document.removeEventListener('mousemove', this.resize);
        window.requestAnimationFrame(async () => {
          this.setPanesRatio();
          await setItem(this.localStorageKey, {
            firstPaneRatio: this.firstPaneRatio,
            secondPaneRatio: this.secondPaneRatio,
          });
          this.firstPaneSize = null;
          this.secondPaneSize = null;
        });
      },
    },
  };
</script>

<style src="./SplitPane.scss" lang="scss"></style>
