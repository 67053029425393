


































  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import api from '@/api';
  import Icon from '@/components/Icon/Icon.vue';
  import { CardId } from '@/components/SetupGuide/SetupGuide.vue';

  export default Vue.extend({
    components: {
      Icon,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      completed: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      updateCardMetric(): void {
        api.uiEvents.create({
          event_type: `setup_guide_card_clicked_${this.id}`,
        });
      },
    },
    computed: {
      ...mapGetters('authentication', ['setupGuideCards']),
      icon(): string {
        if (this.completed) return 'check';
        switch (this.id) {
          case CardId.ImportEmails:
            return 'migration-blue';
          case CardId.EnableIMAP:
            return 'trusted-device-blue';
          case CardId.EnableTotp:
            return 'setting-blue';
          case CardId.AddAlias:
            return 'migration-blue';
          default:
            return '';
        }
      },
      to(): string {
        switch (this.id) {
          case CardId.ImportEmails:
            return 'EmailMigration';
          case CardId.EnableIMAP:
            return 'ImapTokens';
          case CardId.EnableTotp:
            return 'AccountSettings';
          case CardId.AddAlias:
            return 'Aliases/Personal';
          default:
            return '';
        }
      },
      hash(): string {
        switch (this.id) {
          case CardId.ImportEmails:
            return '#import-emails';
          case CardId.EnableIMAP:
            return '#enable-imap';
          case CardId.EnableTotp:
            return '#two-factor-authentication';
          case CardId.AddAlias:
            return '#add-alias';
          default:
            return '';
        }
      },
      titleText(): string {
        switch (this.id) {
          case CardId.ImportEmails:
            return this.$gettext('Move from another email provider');
          case CardId.EnableIMAP:
            return this.$gettext('Use your favorite email client');
          case CardId.EnableTotp:
            return this.$gettext('Enable 2FA for extra security');
          case CardId.AddAlias:
            return this.$gettext('Create an alias');
          default:
            return '';
        }
      },
      completedTitleText(): string {
        switch (this.id) {
          case CardId.ImportEmails:
            return this.$gettext('You’re in the know');
          case CardId.EnableIMAP:
            return this.$gettext('You’ve set up IMAP');
          case CardId.EnableTotp:
            return this.$gettext('You’ve set up 2FA for your account');
          case CardId.AddAlias:
            return this.$gettext('You’ve created an alias');
          default:
            return '';
        }
      },
      paragraphText(): string {
        switch (this.id) {
          case CardId.ImportEmails:
            return this.$gettext(
              'Easily migrate from another email provider such as Google, Outlook, Yahoo, or others.'
            );
          case CardId.EnableIMAP:
            return this.$gettext(
              'Enable IMAP, then add a device to get a set of credentials for an email client.'
            );
          case CardId.EnableTotp:
            return this.$gettext(
              "Then, if someone knows your password, they won't be able to access your account without your verification device."
            );
          case CardId.AddAlias:
            return this.$gettext(
              'You get an unlimited amount of extra StartMail email addresses, known as aliases. Use them to keep your main address exclusive.'
            );
          default:
            return '';
        }
      },
      completedParagraphText(): string {
        switch (this.id) {
          case CardId.ImportEmails:
            return this.$gettext(
              'Migrated emails and contacts will be in designated folders within your inbox and contacts.'
            );
          case CardId.EnableIMAP:
            return this.$gettext(
              'Now add the set of credentials to your email client to access your StartMail emails.'
            );
          case CardId.EnableTotp:
            return this.$gettext(
              'Each time you log in, you will be asked to enter your 2FA code.'
            );
          case CardId.AddAlias:
            return this.$gettext(
              'You can manage your alias preferences in the Settings.'
            );
          default:
            return '';
        }
      },
    },
  });
