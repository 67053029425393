import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import folderUtilsMixin from '@/mixins/folder-utils-mixin';

export default Vue.extend({
  mixins: [folderUtilsMixin],
  computed: {
    ...mapState({
      selectedMessagesIds: (state: any) => state.messages.selectedMessagesIds,
    }),
    ...mapGetters(['folderById']),
  },
  methods: {
    ...mapActions([
      'loadMessages',
      'moveMessagesToFolder',
      'setToastMessage',
      'setLoadingMessages',
    ]),
    moveSelectedMessages(this: any, { folderId }: { folderId: string }) {
      this.setLoadingMessages(true);

      const targetFolder = this.folderById(folderId);
      const folderName = this.getFolderName(targetFolder);

      return this.moveMessagesToFolder({
        messagesIds: this.selectedMessagesIds,
        folderId,
      })
        .then((messages: Message[]) => {
          const updatedCount = messages.length;
          const translatedText = this.$ngettext(
            '1 message moved to %{ folderName }',
            '%{ updatedCount } messages moved to %{ folderName }',
            updatedCount
          );
          this.setToastMessage({
            message: this.$gettextInterpolate(
              translatedText,
              { updatedCount, folderName },
              true
            ),
          });
          return this.loadMessages();
        })
        .catch((err: Error) => {
          this.setToastMessage({
            message: this.$gettext('Sorry, we could not move your messages'),
          });
          this.loadMessages();
          throw err;
        })
        .finally(() => this.setLoadingMessages(false));
    },
  },
});
