const overflowRegex = /(auto|scroll)/;

export function closestScrollElement(el: HTMLElement): HTMLElement {
  const { overflow, overflowX, overflowY } = window.getComputedStyle(el);

  return overflowRegex.test('' + overflow + overflowX + overflowY)
    ? el
    : getScrollParent(el);
}

export function getScrollParent(el: HTMLElement): HTMLElement {
  const style = window.getComputedStyle(el);
  const excludeStaticParent = style.position === 'absolute';

  if (style.position === 'fixed') return document.body;

  for (let parent: HTMLElement | null = el; (parent = parent.parentElement); ) {
    const { position, overflow, overflowX, overflowY } =
      window.getComputedStyle(parent);
    if (excludeStaticParent && position === 'static') {
      continue;
    }
    if (overflowRegex.test('' + overflow + overflowY + overflowX))
      return parent;
  }

  return document.body;
}
