<template>
  <section class="messages-second-header">
    <div class="app-header__selection-actions">
      <div class="app-header__selection-status">
        <Checkbox
          v-test:messagesActionsCheck
          id="selection"
          :key="Boolean(allMessagesSelected)"
          :checked="allMessagesSelected && Boolean(activeMessagesCount)"
          :indeterminate="someMessagesSelected"
          label="selection"
          :disabled="activeMessagesCount === 0"
          has-hidden-label
          @change="onSelectionChanged($event)"
          v-tooltip="{
            placement: 'right',
            content: selectAllText,
            disabled: activeMessagesCount === 0,
          }"
        />
        <p
          v-if="someMessagesSelected"
          class="messages-second-header__selection-label"
        >
          {{ selectedMessagesText }}
        </p>
      </div>

      <div
        v-if="someMessagesSelected"
        class="action-bar__row-left messages-second-header__large-screen-only"
      >
        <div class="action-bar__group">
          <MoveToFolderModal
            :busy-moving="isBusy.moving"
            @moveToFolder="moveMessages"
            v-test:moveToFolder
          />
          <ActionBarButton
            v-if="allSelectedMessagesSpam"
            v-tooltip="markAsNotSpamButtonText"
            icon="not-spam"
            @click="onMarkAsNotSpamClicked"
            v-test:markAsNotSpam
          />
          <ActionBarButton
            v-else
            v-tooltip="markAsSpamButtonText"
            icon="spam"
            @click="onMarkAsSpamClicked"
            v-test:markAsSpam
          />
          <ActionBarButton
            v-tooltip="bulkToggleReadButtonText"
            :icon="someSelectedMessagesUnread ? 'unread' : 'read'"
            @click="onToggleReadClicked"
            :key="someSelectedMessagesUnread"
            v-test:toggleReadStatus
          />
          <DeleteConfirmationModal
            v-if="allSelectedMessagesTrashed"
            @delete="onDeleteClicked"
            :ok-button-text="deleteMessageText"
            :warning-text="deleteWarningText"
            v-test:deleteMessages
          >
            <template v-slot:DeleteButton="deleteConfirmationModalScope">
              <ActionBarButton
                v-tooltip="deleteSelectedMessagesText"
                icon="delete-new"
                @click="deleteConfirmationModalScope.toggle"
              />
            </template>
          </DeleteConfirmationModal>
          <ActionBarButton
            v-else
            v-tooltip="trashMessagesText"
            icon="trash"
            @click="onTrashClicked"
            v-test:trashMessages
          />

          <ActionBarButton
            v-tooltip="bulkFlagButtonText"
            :key="allSelectedMessagesFlagged ? 'key-1' : 'key-2'"
            :icon="allSelectedMessagesFlagged ? 'flag-outline' : 'flag'"
            @click="onFlagClicked"
          />
        </div>
      </div>
      <div
        v-if="someMessagesSelected"
        class="action-bar__row-left messages-second-header__small-screen-only"
      >
        <div class="action-bar__group">
          <DeleteConfirmationModal
            v-if="allSelectedMessagesTrashed"
            @delete="onDeleteClicked"
            :ok-button-text="deleteMessageText"
            :warning-text="deleteWarningText"
            v-test:deleteMessages
          >
            <template v-slot:DeleteButton="deleteConfirmationModalScope">
              <ActionBarButton
                v-tooltip="deleteText"
                :title="deleteSelectedMessagesText"
                icon="delete-new"
                @click="deleteConfirmationModalScope.toggle"
                class="messages-second-header__delet-button"
              />
            </template>
          </DeleteConfirmationModal>
          <ActionBarButton
            v-else
            v-tooltip="trashMessagesText"
            icon="trash"
            @click="onTrashClicked"
            v-test:trashMessages
          />
        </div>

        <div class="action-bar__group">
          <Dropdown class="dropdown--align-left">
            <template v-slot:button="{ isOpen, toggle }">
              <ActionBarButton
                :active="isOpen"
                icon="menu-horizontal"
                @click="toggle"
                v-test:moreButton
              />
            </template>
            <template v-slot:content="{ toggle }">
              <ul class="link-list" @click="toggle">
                <li>
                  <MoveToFolderModal
                    :button-text="moveText"
                    :busy-moving="isBusy.moving"
                    @moveToFolder="moveMessages"
                    v-test:moveToFolder
                  >
                    <template v-slot:toggle="{ toggle: modalToggle }">
                      <button
                        type="button"
                        class="button button--clean link-list__item link-list__item--move-button"
                        @click="modalToggle"
                      >
                        <Icon symbol="folder" />
                        {{ moveText }}
                      </button>
                    </template>
                  </MoveToFolderModal>
                </li>
                <li>
                  <button
                    type="button"
                    class="button button--clean link-list__item"
                    @click="onFlagClicked"
                    :title="bulkFlagButtonText"
                  >
                    <Icon
                      :symbol="
                        allSelectedMessagesFlagged ? 'flag-outline' : 'flag'
                      "
                    />
                    {{ toggleFlagText }}
                  </button>
                </li>
                <li>
                  <button
                    v-if="allSelectedMessagesSpam"
                    type="button"
                    class="button button--clean link-list__item"
                    @click="onMarkAsNotSpamClicked"
                    :title="markAsNotSpamButtonText"
                  >
                    <Icon symbol="not-spam" />
                    {{ notSpamText }}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="button button--clean link-list__item"
                    @click="onMarkAsSpamClicked"
                    :title="markAsSpamButtonText"
                  >
                    <Icon symbol="spam" />
                    {{ spamText }}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="button button--clean link-list__item"
                    @click="onToggleReadClicked"
                    :title="bulkToggleReadButtonText"
                  >
                    <Icon
                      :symbol="someSelectedMessagesUnread ? 'unread' : 'read'"
                    />
                    {{ toggleReadStatusText }}
                  </button>
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <div class="action-bar__row-right">
      <div class="action-bar__group" v-if="!someMessagesSelected">
        <div class="message-list__filter" v-if="!searchResultView">
          <Dropdown>
            <template v-slot:button="{ isOpen, toggle }">
              <ActionBarButton
                :active="isOpen"
                :dropdown="true"
                :button-text="filterByText"
                icon="filter"
                @click="toggle"
                :title="filterByText"
                v-test:messageStatusFilter
                class="button--small button--auto-hide-label"
                :class="{
                  'button--active-badge': activeFilters.length,
                }"
              />
            </template>
            <template v-slot:content>
              <ul class="link-list">
                <li v-for="{ name, value } in filterOptions" :key="value">
                  <Checkbox
                    class="link-list__item"
                    :id="value"
                    :label="name"
                    :checked="isActiveFilter(value)"
                    @change="toggleFilter(value, $event)"
                  />
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
        <div class="message-list__sort" v-if="!searchResultView">
          <Dropdown>
            <template v-slot:button="{ isOpen, toggle }">
              <ActionBarButton
                :active="isOpen"
                :dropdown="true"
                icon="sort"
                @click="toggle"
                v-test:sortTypeButton
                :title="sortByText"
                :button-text="sortByText"
                class="button--small button--auto-hide-label"
              />
            </template>
            <template v-slot:content>
              <RadioInput
                class="link-list"
                :options="sortingOptions"
                option-class="link-list__item"
                id="sortOrder-large-screen"
                v-model="sortOrder"
              />
            </template>
          </Dropdown>
        </div>
        <LayoutDropdown />
      </div>

      <div class="action-bar__group">
        <Pagination />
      </div>
    </div>
  </section>
</template>

<script>
  import Mousetrap from 'mousetrap';
  import { mapGetters, mapActions, mapState } from 'vuex';
  import ActionBarButton from '@/components/ActionBarButton/ActionBarButton';
  import Checkbox from '@/components/form/Checkbox';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal';
  import Dropdown from '@/components/Dropdown/Dropdown';
  import MoveToFolderModal from '@/components/MoveToFolderModal/MoveToFolderModal';
  import Icon from '@/components/Icon/Icon';
  import Pagination from '@/components/Pagination/Pagination';
  import RadioInput from '@/components/form/RadioInput';
  import LayoutDropdown from '@/components/LayoutDropdown/LayoutDropdown';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import moveSelectedMessagesMixin from '@/mixins/move-selected-messages-mixin';
  import { MAIL_SEARCH } from '@/router/named-routes';
  import {
    DATE_ASCENDING,
    DATE_DESCENDING,
    SENDER_ASCENDING,
    SENDER_DESCENDING,
    RECIPIENT_ASCENDING,
    RECIPIENT_DESCENDING,
    INBOUND_SORT_ORDER,
    OUTBOUND_SORT_ORDER,
  } from '@/store/sorting/sorting';
  import {
    FILTER_UNREAD,
    FILTER_FLAGGED,
    FILTER_ATTACHMENT,
    FILTER_ENCRYPTED,
    FILTER_OPTIONS,
  } from '@/store/mail/modules/filters';

  export default {
    components: {
      ActionBarButton,
      Checkbox,
      DeleteConfirmationModal,
      Dropdown,
      MoveToFolderModal,
      Pagination,
      RadioInput,
      Icon,
      LayoutDropdown,
    },
    mixins: [moveSelectedMessagesMixin, folderUtilsMixin, asyncActionsMixin],
    data() {
      return {
        messagesMovedToSpamCount: 0,
      };
    },
    computed: {
      ...mapGetters([
        'activeFolder',
        'messageById',
        'folderById',
        'nextMessageId',
        'pagination/currentPageSize',
        'previousMessageId',
        'selectedMessages',
      ]),
      ...mapGetters('features', ['featureById']),
      ...mapState({
        activeMessagesIds: (state) => state.messages.activeMessagesIds,
        selectedMessagesIds: (state) => state.messages.selectedMessagesIds,
        sortOrderState: (state) => state.sorting.sortOrder,
        activeFilters: (state) => state.filters.activeFilters,
        paginationPageNumber: (state) => state.pagination.page,
      }),
      outbound() {
        return ['sent', 'drafts'].includes(this.activeFolder?.type);
      },
      selectedMessagesLength() {
        return this.selectedMessages.length;
      },
      activeMessagesCount() {
        return this.activeMessagesIds.length;
      },
      allMessagesSelected() {
        return (
          this.selectedMessagesLength === this.activeMessagesCount &&
          this.selectedMessagesIds.every((selectedMessagesId) =>
            this.activeMessagesIds.includes(selectedMessagesId)
          )
        );
      },
      allSelectedMessagesFlagged() {
        return this.selectedMessages.every((message) => message.flagged);
      },
      allSelectedMessagesTrashed() {
        return this.selectedMessages
          .map(({ folder_id }) => this.folderById(folder_id))
          .filter(Boolean)
          .every(({ type }) => type === 'trash');
      },
      allSelectedMessagesSpam() {
        return (
          this.selectedMessages.length > 0 &&
          this.selectedMessages
            .map(({ folder_id }) => this.folderById(folder_id))
            .filter(Boolean)
            .every(({ type }) => type === 'junk')
        );
      },
      someSelectedMessagesUnread() {
        return this.selectedMessages.some((message) => message.unread);
      },
      bulkSelectButtonText() {
        return this.allMessagesSelected
          ? this.$gettext('Deselect all messages')
          : this.$gettext('Select all messages');
      },
      bulkFlagButtonText() {
        return this.allSelectedMessagesFlagged
          ? this.$ngettext(
              'Unflag message',
              'Unflag messages',
              this.selectedMessagesLength
            )
          : this.$ngettext(
              'Flag message',
              'Flag messages',
              this.selectedMessagesLength
            );
      },
      bulkToggleReadButtonText() {
        return this.someSelectedMessagesUnread
          ? this.$ngettext(
              'Mark message read',
              'Mark messages read',
              this.selectedMessagesLength
            )
          : this.$ngettext(
              'Mark message unread',
              'Mark messages unread',
              this.selectedMessagesLength
            );
      },
      markAsSpamButtonText() {
        return this.$ngettext(
          'Mark message as spam',
          'Mark messages as spam',
          this.selectedMessagesLength
        );
      },
      markAsNotSpamButtonText() {
        return this.$ngettext(
          'Mark message as not spam',
          'Mark messages as not spam',
          this.selectedMessagesLength
        );
      },
      selectedMessagesText() {
        return this.$gettextInterpolate(this.$gettext('%{ n } selected'), {
          n: this.selectedMessagesLength,
        });
      },
      selectAllText() {
        return this.selectedMessagesLength
          ? this.$gettext('Deselect all')
          : this.$gettext('Select All');
      },
      searchResultView() {
        return this.$route.name === MAIL_SEARCH;
      },
      goBackText() {
        return this.$gettext('Go back');
      },
      trashMessagesText() {
        return this.$ngettext(
          'Move selected message to Trash',
          'Move selected messages to Trash',
          this.selectedMessagesLength
        );
      },
      deleteSelectedMessagesText() {
        return this.$ngettext(
          'Delete selected message',
          'Delete selected messages',
          this.selectedMessagesLength
        );
      },
      deleteText() {
        return this.$gettext('Delete');
      },
      deleteMessageText() {
        return this.$ngettext(
          'Delete message',
          'Delete messages',
          this.selectedMessagesLength
        );
      },
      deleteWarningText() {
        return this.$ngettext(
          'The selected message will be permanently deleted. It is not possible to undo this action.',
          'The selected messages will be permanently deleted. It is not possible to undo this action.',
          this.selectedMessagesLength
        );
      },
      trashText() {
        return this.$gettext('Trash');
      },
      moveText() {
        return this.$gettext('Move');
      },
      spamText() {
        return this.$gettext('Spam');
      },
      notSpamText() {
        return this.$gettext('Not Spam');
      },
      toggleReadStatusText() {
        return this.someSelectedMessagesUnread
          ? this.$gettext('Mark as read')
          : this.$gettext('Mark as unread');
      },
      toggleFlagText() {
        return this.allSelectedMessagesFlagged
          ? this.$gettext('Unflag')
          : this.$gettext('Flag');
      },
      someMessagesSelected() {
        return this.selectedMessagesLength > 0;
      },
      skeletonUiItemCount() {
        return this['pagination/currentPageSize'] || 4;
      },
      sortOrder: {
        get() {
          return this.sortOrderState;
        },
        set(order) {
          this.setSortOrder({ order });
          this.$router.replace({
            ...this.$route,
            query: { ...this.$route.query, sortOrder: order },
          });
        },
      },
      sortByText() {
        return this.$gettext('Sort by');
      },
      sortingOptions() {
        const sort_options = this.outbound
          ? OUTBOUND_SORT_ORDER
          : INBOUND_SORT_ORDER;
        return Array.from(sort_options.keys()).map((value) => ({
          name: this.translateSortOption(value),
          value,
        }));
      },
      filterByText() {
        if (this.activeFilters.length === 1) {
          const filter = this.activeFilters.map(this.translateFilterOption);
          return this.$gettextInterpolate(
            this.$gettext('Filter by: %{ filter }'),
            { filter }
          );
        } else if (this.activeFilters.length === 0) {
          return this.$gettext('Filter by');
        }
        return this.$gettextInterpolate(this.$gettext('%{ number } filters'), {
          number: this.activeFilters.length,
        });
      },
      filterOptions() {
        return Array.from(FILTER_OPTIONS.entries())
          .filter(([, { featureFlag }]) => {
            if (featureFlag) {
              const feature = this.featureById(featureFlag);
              return feature
                ? feature.enabled
                : process.env.NODE_ENV !== 'production';
            }

            return true;
          })
          .map(([value]) => ({
            name: this.translateFilterOption(value),
            value,
          }));
      },
    },
    watch: {
      paginationPageNumber() {
        this.deselectAllMessages();
      },
    },
    mounted() {
      // keyboard shortcuts
      const guardEmptySelection = (fn) => () =>
        this.selectedMessagesIds.length > 0 && fn();

      Mousetrap.bind(
        ['d', 'del', 'backspace'],
        guardEmptySelection(this.onTrashClicked)
      );
      Mousetrap.bind('s', guardEmptySelection(this.onMarkAsSpamClicked));
      Mousetrap.bind('n', guardEmptySelection(this.onToggleReadClicked));
      Mousetrap.bind('f', guardEmptySelection(this.onFlagClicked));
      Mousetrap.bind('esc', this.onBackClicked);
    },
    beforeDestroy() {
      Mousetrap.unbind(['d', 'del', 'backspace', 's', 'n', 'f', 'esc']);
    },
    methods: {
      ...mapActions([
        'changeMessagesFlagStatus',
        'changeMessagesReadStatus',
        'deselectAllMessages',
        'deleteTrashedMessages',
        'loadMessages',
        'markMessagesAsSpam',
        'markMessagesAsNotSpam',
        'moveMessagesToTrash',
        'selectAllMessages',
        'setToastMessage',
        'setSortOrder',
        'setLoadingMessages',
      ]),
      ...mapActions('filters', ['setFilter']),
      isActive(folder) {
        return this.$route.params.folder === folder.id;
      },
      onTrashClicked: asyncAction('trashing', function () {
        this.setLoadingMessages(true);
        this.updateRoute();
        return this.moveMessagesToTrash({
          messagesIds: this.selectedMessagesIds,
        })
          .then((messages) => {
            const updatedCount = messages.length;
            const translatedText = this.$ngettext(
              'Message moved to Trash',
              '%{ updatedCount } messages moved to Trash',
              updatedCount
            );
            const toastMessage = this.$gettextInterpolate(translatedText, {
              updatedCount,
            });
            this.setToastMessage({ message: toastMessage });
            return this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not move your messages to Trash'
              ),
            });
            this.loadMessages();
          })
          .finally(() => this.setLoadingMessages(false));
      }),
      onDeleteClicked: asyncAction('deleting', function () {
        this.setLoadingMessages(true);
        this.updateRoute();
        return this.deleteTrashedMessages({
          messagesIds: this.selectedMessagesIds,
        })
          .then((messages) => {
            const updatedCount = messages.length;
            const translatedText = this.$ngettext(
              'Message permanently deleted',
              '%{ updatedCount } messages permanently deleted',
              updatedCount
            );
            const toastMessage = this.$gettextInterpolate(translatedText, {
              updatedCount,
            });
            this.setToastMessage({ message: toastMessage });
            return this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not delete your trashed messages'
              ),
            });
            this.loadMessages();
          })
          .finally(() => this.setLoadingMessages(false));
      }),
      onFlagClicked: asyncAction('flagging', function () {
        const flagged = !this.allSelectedMessagesFlagged;
        return this.changeMessagesFlagStatus({
          messagesIds: this.selectedMessagesIds,
          flagged,
        }).catch(() => {
          const translatedText = this.$ngettext(
            'Sorry, we could not %{ flagStatus } your message',
            'Sorry, we could not %{ flagStatus } your messages',
            this.selectedMessagesIds.length
          );
          this.setToastMessage({
            message: this.$gettextInterpolate(translatedText, {
              flagStatus: flagged ? 'flag' : 'unflag',
            }),
          });
          this.loadMessages();
        });
      }),
      onToggleReadClicked: asyncAction('togglingRead', function () {
        /*
         * When only read messages are selected, mark them unread
         * When only unread messages are selected, mark them read
         * When read and unread messages are selected, mark all the selected messages as read.
         */

        // Check if all the messages in selected messages list are read
        const allMessagesRead = !this.someSelectedMessagesUnread;
        const numSelectedMessages = this.selectedMessagesIds.length;
        return this.changeMessagesReadStatus({
          messagesIds: this.selectedMessagesIds,
          unread: allMessagesRead,
        })
          .then(() => {
            this.setToastMessage({
              message: allMessagesRead
                ? this.$ngettext(
                    'Message marked as unread',
                    'Messages marked as unread',
                    numSelectedMessages
                  )
                : this.$ngettext(
                    'Message marked as read',
                    'Messages marked as read',
                    numSelectedMessages
                  ),
            });
          })
          .catch((err) => {
            this.setToastMessage({
              message: allMessagesRead
                ? this.$ngettext(
                    'Sorry, we could not mark your message as unread',
                    'Sorry, we could not mark your messages as unread',
                    numSelectedMessages
                  )
                : this.$ngettext(
                    'Sorry, we could not mark your message as read',
                    'Sorry, we could not mark your messages as read',
                    numSelectedMessages
                  ),
            });
            this.loadMessages();
            throw err;
          });
      }),
      onMarkAsSpamClicked: asyncAction('markingAsSpam', function () {
        this.setLoadingMessages(true);
        this.updateRoute();
        return this.markMessagesAsSpam({
          messagesIds: this.selectedMessagesIds,
        })
          .then((messages) => {
            const updatedCount = messages.length;
            const translatedText = this.$ngettext(
              'Message marked as Spam and moved to Spam folder',
              '%{ updatedCount } messages marked as Spam and moved to Spam folder',
              updatedCount
            );
            const toastMessage = this.$gettextInterpolate(translatedText, {
              updatedCount,
            });
            this.setToastMessage({ message: toastMessage });
            return this.loadMessages();
          })
          .catch((err) => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not mark your messages as Spam'
              ),
            });
            this.loadMessages();
            throw err;
          })
          .finally(() => this.setLoadingMessages(false));
      }),
      onMarkAsNotSpamClicked: asyncAction('markingAsNotSpam', function () {
        this.setLoadingMessages(true);
        this.updateRoute();
        return this.markMessagesAsNotSpam({
          messagesIds: this.selectedMessagesIds,
        })
          .then((messages) => {
            const updatedCount = messages.length;
            const translatedText = this.$ngettext(
              'Message marked as not spam and moved to Inbox',
              '%{ updatedCount } messages marked as not spam and moved to Inbox',
              updatedCount
            );

            const toastMessage = this.$gettextInterpolate(translatedText, {
              updatedCount,
            });
            this.setToastMessage({ message: toastMessage });
            return this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not mark your messages as not spam'
              ),
            });
            this.loadMessages();
          })
          .finally(() => this.setLoadingMessages(false));
      }),
      moveMessages: asyncAction('moving', function (folderId, modal) {
        this.updateRoute();
        return this.moveSelectedMessages({ folderId }).then(modal.toggle);
      }),
      onSelectionChanged() {
        if (this.allMessagesSelected || this.someMessagesSelected) {
          this.deselectAllMessages();
        } else {
          this.selectAllMessages();
        }
      },
      onBackClicked() {
        this.deselectAllMessages();
      },
      updateRoute() {
        const { message, ...query } = this.$route.query;
        if (message) {
          this.$router.replace({ ...this.$route, query });
        }
      },
      translateSortOption(option) {
        switch (option) {
          case DATE_ASCENDING:
            return this.$gettext('Date ascending');

          case DATE_DESCENDING:
            return this.$gettext('Date descending (default)');

          case SENDER_ASCENDING:
          case RECIPIENT_ASCENDING:
            return this.$gettext('Name ascending');

          case SENDER_DESCENDING:
          case RECIPIENT_DESCENDING:
            return this.$gettext('Name descending');
        }
      },
      translateFilterOption(option) {
        switch (option) {
          case FILTER_UNREAD:
            return this.$gettext('Unread');
          case FILTER_FLAGGED:
            return this.$gettext('Flagged');
          case FILTER_ATTACHMENT:
            return this.$gettext('Attachment');
          case FILTER_ENCRYPTED:
            return this.$gettext('Encrypted');
        }
      },
      async toggleFilter(filter, active) {
        const activeFilters = await this.setFilter({ filter, active });
        const { page, ...query } = this.$route.query;
        this.$router.replace({
          ...this.$route,
          query: { ...query, filter: activeFilters },
        });
      },
      isActiveFilter(filter) {
        return this.activeFilters.includes(filter);
      },
    },
  };
</script>

<style src="./MessagesActionsHeader.scss" lang="scss"></style>
