export default function truncateMiddle(str: string, maxLength: number): string {
  // Truncate a file name in the middle, always keeping the extension.
  //
  // This cuts at Unicode code point boundaries, but does not work
  // correctly for complex Unicode Emoji Modifier Sequences.
  //
  // Note: this works on arrays of ‘characters’, not directly on
  // strings (containing surrogate pairs), to avoid cutting Unicode
  // characters in half.

  if ([...str].length <= maxLength) return str;

  const [name, extension] = splitExtension(str);
  const nameChars = [...name];
  const extensionChars = [...extension];
  const segmentLength = Math.max(
    1,
    Math.floor((maxLength - extensionChars.length - 1) / 2)
  );
  return [
    ...nameChars.slice(0, segmentLength),
    '…', // U+2026 HORIZONTAL ELLIPSIS
    ...nameChars.slice(-segmentLength),
    ...extensionChars,
  ].join('');
}

function splitExtension(name: string): [string, string] {
  // Split a file name into a base name and an extension (if any).
  const idx = name.lastIndexOf('.');
  if (idx >= 1) {
    // Only when there's some text before it.
    return [name.slice(0, idx), name.slice(idx)];
  } else {
    return [name, ''];
  }
}
