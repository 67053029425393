























  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { MAIL_FOLDER, MAIL_MESSAGE } from '@/router/named-routes';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLInputElement {
    toggleModal: Function;
  }

  export default Vue.extend({
    name: 'DeleteAll',
    components: {
      DeleteConfirmationModal,
      Icon,
    },
    mixins: [asyncActionsMixin, folderUtilsMixin],
    computed: {
      ...mapGetters(['activeFolder', 'activeMessagesCount']),
      ...mapState({
        totalMessages: (state: any) => state.pagination.total,
      }),
      modal(): any {
        return this.$refs.modal;
      },
      deleteText(): string {
        return this.$gettext('Delete');
      },
      deleteWarningText(): string {
        const translated = this.$gettext(
          'All %{ number } messages in the %{ folderName } will be permanently deleted. It is not possible to undo this action.'
        );
        return this.$gettextInterpolate(translated, {
          folderName: this.folderName,
          number: this.totalMessages || '',
        });
      },
      modalHeaderText(): string {
        const translated = this.$gettext(
          'Delete all messages in the %{ folderName }?'
        );
        return this.$gettextInterpolate(translated, {
          folderName: this.folderName,
        });
      },
      folderName(this: any): string {
        return this.getFolderName(this.activeFolder);
      },
    },
    methods: {
      ...mapActions([
        'emptyJunk',
        'emptyTrash',
        'loadMessages',
        'setToastMessage',
      ]),
      onDeleteClicked: asyncAction('deleting', function (this: any) {
        this.updateRoute();
        return this.delete()
          .then(() => {
            this.modal.toggleModal();
            const translatedText = this.$gettext(
              'All the messages in the %{ folderName } have been deleted'
            );
            this.setToastMessage({
              message: this.$gettextInterpolate(translatedText, {
                folderName: this.folderName,
              }),
            });
            this.loadMessages();
          })
          .catch((err: AxiosError) => {
            const translatedText = this.$gettext(
              'Sorry, we could not delete messages in the %{ folderName }'
            );
            this.setToastMessage({
              message: this.$gettextInterpolate(translatedText, {
                folderName: this.folderName,
              }),
            });
            this.loadMessages();
            throw err;
          });
      }),
      async delete() {
        if (this.activeFolder.type === 'junk') {
          return this.emptyJunk();
        }
        if (this.activeFolder.type === 'trash') {
          return this.emptyTrash();
        }
      },
      updateRoute() {
        if (this.$route.name === MAIL_MESSAGE) {
          const { message, ...query } = this.$route.query;
          this.$router.replace({ name: MAIL_FOLDER, query });
        } else if (this.$route.name === MAIL_FOLDER) {
          const { message, ...query } = this.$route.query;
          if (message) {
            this.$router.replace({ ...(this as any).$route, query });
          }
        }
      },
    },
  });
