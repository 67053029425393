<template>
  <p
    v-if="state.loading"
    :key="`loadingMessage-${state.loading}`"
    class="panel-user-message"
  >
    <translate>Loading message…</translate>
  </p>
  <ErrorView v-else-if="state.error" :error-type="state.error" />

  <section v-else-if="activeMessage" class="message-wrapper">
    <SwipeNavigation
      class="panel"
      @swipeRight="$root.$emit('readMessageInCurrentView', previousMessageId)"
      @swipeLeft="$root.$emit('readMessageInCurrentView', nextMessageId)"
    >
      <Message
        :message="activeMessage"
        :attachments-download-link="downloadAllFilesUrl"
        :show-external-images="showExternalImages"
        :external-link-warning="preferences.external_link_warning"
        :show-badge-dropdown="true"
        :display-formats="displayFormats"
        :dark-mode="darkMode"
        @mailToClicked="onMailToClicked"
        @showExternalImages="onClickShowExternalImages"
      >
        <template v-slot:actions>
          <div class="action-bar__row">
            <div class="action-bar__row-left">
              <div class="action-bar__group">
                <Dropdown
                  class="message__small-screen-only dropdown--align-left"
                  v-test:replyForwardDropdown
                >
                  <template v-slot:button="{ isOpen, toggle }">
                    <ActionBarButton
                      :dropdown="true"
                      :active="isOpen"
                      icon="reply"
                      @click="toggle"
                    />
                  </template>
                  <template v-slot:content="{ toggle }">
                    <ul class="link-list" @click="toggle">
                      <li>
                        <button
                          type="button"
                          class="button button--clean link-list__item"
                          v-test:replyButton
                          @click="replyClicked"
                        >
                          {{ replyText }}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="button button--clean link-list__item"
                          @click="replyAllClicked"
                        >
                          {{ replyToAllText }}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="button button--clean link-list__item"
                          v-test:forwardButton
                          @click="forwardClicked"
                        >
                          {{ forwardText }}
                        </button>
                      </li>
                    </ul>
                  </template>
                </Dropdown>
                <ActionBarButton
                  class="message__large-screen-only button--subtle button--small"
                  :button-text="replyText"
                  :title="replyText"
                  v-test:replyButton
                  icon="reply"
                  @click="replyClicked"
                />
                <ActionBarButton
                  class="message__large-screen-only button--subtle button--small"
                  :button-text="replyToAllText"
                  :title="replyToAllText"
                  icon="reply-all"
                  @click="replyAllClicked"
                />
                <ActionBarButton
                  class="message__large-screen-only button--subtle button--small"
                  :button-text="forwardText"
                  :title="forwardText"
                  icon="forward"
                  v-test:forwardButton
                  @click="forwardClicked"
                />
              </div>
              <div class="action-bar__group">
                <MoveToFolderModal
                  :busy-moving="isBusy.moving"
                  @moveToFolder="onMoveMessage"
                />
                <ActionBarButton
                  class="button--subtle button--small"
                  v-if="isSpamMessage"
                  icon="not-spam"
                  @click="markAsNotSpamClicked"
                  v-test:markAsNotSpam
                  v-tooltip="{
                    position: 'bottom',
                    content: markAsNotSpamButtonText,
                  }"
                />
                <ActionBarButton
                  class="button--subtle button--small"
                  v-else
                  icon="spam"
                  @click="markAsSpamClicked"
                  v-test:markAsSpam
                  v-tooltip="{
                    position: 'bottom',
                    content: markAsSpamButtonText,
                  }"
                />
                <DeleteConfirmationModal
                  v-if="activeFolder.type === 'trash'"
                  :ok-button-text="deleteMessageText"
                  :warning-text="deleteWarningText"
                  @delete="onDeleteClicked"
                >
                  <template v-slot:DeleteButton="deleteConfirmationModalScope">
                    <ActionBarButton
                      class="button--subtle button--small"
                      icon="trash"
                      v-tooltip="{
                        position: 'bottom',
                        content: deleteMessageText,
                      }"
                      @click="deleteConfirmationModalScope.toggle"
                    />
                  </template>
                </DeleteConfirmationModal>
                <ActionBarButton
                  class="button--subtle button--small"
                  v-else
                  icon="trash"
                  @click="onTrashClicked"
                  v-tooltip="{ position: 'bottom', content: moveToTrashText }"
                />
                <ActionBarButton
                  icon="unread"
                  @click="markAsUnreadClick"
                  class="button--subtle button--small"
                  v-test:markAsUnreadButton
                  v-tooltip="{ position: 'bottom', content: markAsUnreadText }"
                  :disabled="isBusy.markingAsUnread"
                />
                <ActionBarButton
                  :active="activeMessage.flagged"
                  icon="flag"
                  @click="toggleMessageFlaggedClicked"
                  class="button--subtle button--small"
                  v-tooltip="{
                    position: 'bottom',
                    content: toggleMessageFlaggedDisplayValue,
                  }"
                />
              </div>
              <div class="action-bar__group">
                <Dropdown v-test:secondaryActionsDropdown>
                  <template v-slot:button="{ isOpen, toggle }">
                    <ActionBarButton
                      class="button--subtle button--small"
                      :active="isOpen"
                      icon="menu-horizontal"
                      @click="toggle"
                      v-tooltip="{
                        position: 'bottom',
                        content: moreText,
                        disabled: isOpen,
                      }"
                    />
                  </template>
                  <template v-slot:content="{ toggle }">
                    <ul class="link-list" @click="toggle">
                      <li>
                        <a
                          :href="messageSourceUrl"
                          target="_blank"
                          v-test:viewSourceButton
                          class="button button--subtle link-list__item"
                        >
                          <translate>View source</translate>
                        </a>
                      </li>
                      <li
                        v-if="showPrintButton"
                        class="message-wrapper__print-button"
                      >
                        <button
                          type="button"
                          class="button button--subtle link-list__item"
                          v-test:printButton
                          @click="onPrintClick"
                        >
                          <translate>Print</translate>
                        </button>
                      </li>
                      <li>
                        <BlockSenderModal
                          :sender="
                            activeMessage.from && activeMessage.from.email
                          "
                        />
                      </li>
                      <li>
                        <ReportPhishingConfirmationModal
                          @report-phishing="onReportPhishingClicked"
                        >
                          <template
                            v-slot:ReportPhishingButton="reportPhishingModalScope"
                          >
                            <button
                              type="button"
                              class="button button--subtle link-list__item"
                              v-test:reportPhishingButton
                              @click="reportPhishingModalScope.toggle"
                            >
                              <translate>Report phishing</translate>
                            </button>
                          </template>
                        </ReportPhishingConfirmationModal>
                      </li>
                    </ul>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div class="action-bar__row-right message__large-screen-only">
              <div class="action-bar__group">
                <div class="action-bar__group" v-if="activeMessageIndex !== -1">
                  <div class="action-bar__group">
                    <ActionBarButton
                      :title="previousMessageText"
                      icon="arrow-left"
                      @click="
                        $root.$emit(
                          'readMessageInCurrentView',
                          previousMessageId
                        )
                      "
                      :disabled="!previousMessageId"
                      v-tooltip="{
                        position: 'bottom',
                        content: previousMessageText,
                      }"
                      v-test:previousMessageButton
                    />
                    <span
                      >{{ activeMessageNumberInList }} of
                      {{ paginationTotal }}</span
                    >
                    <ActionBarButton
                      icon="arrow-right"
                      @click="
                        $root.$emit('readMessageInCurrentView', nextMessageId)
                      "
                      :disabled="!nextMessageId"
                      v-test:nextMessageButton
                      v-tooltip="{
                        position: 'bottom',
                        content: nextMessageText,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:top="{ messageDecryptionStatus }">
          <MessageStatus
            v-if="
              activeMessage.flags.is_encrypted || activeMessage.flags.is_signed
            "
            :encrypted="activeMessage.flags.is_encrypted"
            :signed="activeMessage.flags.is_signed"
            :erroneous-signatures="erroneousSignatures"
            :message-decryption-status="messageDecryptionStatus"
            :pgp-keys="
              activeMessage.pgp && activeMessage.pgp.encryption.recipient_keys
            "
          />
        </template>
      </Message>
    </SwipeNavigation>
  </section>

  <p v-else class="panel-user-message">
    <translate>Message does not exist.</translate>
  </p>
</template>

<script>
  import Mousetrap from 'mousetrap';
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
  import * as endpoints from '@/api/endpoints';
  import ActionBarButton from '@/components/ActionBarButton/ActionBarButton';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal';
  import BlockSenderModal from '@/components/BlockSenderModal';
  import ReportPhishingConfirmationModal from '@/components/ReportPhishingConfirmationModal';
  import Dropdown from '@/components/Dropdown/Dropdown';
  import Message from '@/components/Message/Message';
  import MessageStatus from '@/components/MessageStatus/MessageStatus';
  import MoveToFolderModal from '@/components/MoveToFolderModal/MoveToFolderModal';
  import SwipeNavigation from '@/components/SwipeNavigation/SwipeNavigation';
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView';
  import { mixin as timeMixin } from '@/lib/time';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import {
    MAIL_COMPOSE,
    MAIL_SEARCH,
    MAIL_FOLDER,
    MAIL_MESSAGE,
  } from '@/router/named-routes';
  import { SET_ACTIVE_MESSAGE } from '@/store/mail/modules/messages';
  import { getDateFormat, getTimeFormat } from '@/lib/dateTimeFormats';

  const PGP_SIGNATURE_OK = 0;

  export default {
    name: 'MessageWrapper',
    components: {
      Dropdown,
      DeleteConfirmationModal,
      MoveToFolderModal,
      ActionBarButton,
      Message,
      MessageStatus,
      BlockSenderModal,
      ReportPhishingConfirmationModal,
      SwipeNavigation,
      ErrorView,
    },
    mixins: [timeMixin, folderUtilsMixin, asyncActionsMixin],
    /**
     * XXX If you change this function, ensure you keep the resetState() method below consistent.
     */
    data() {
      return {
        state: { loading: true },
        showExternalImages: false,
        showPrintButton: !!window.print,
      };
    },
    computed: {
      ...mapState({
        activeMessageId: (state) => state.messages.activeMessageId,
        paginationTotal: (state) => state.pagination.total,
        darkMode: (state) => state.settings.darkMode,
        preferences: (state) => state.authentication.user.preferences,
      }),
      ...mapGetters([
        'activeFolder',
        'activeMessageIndex',
        'activeMessage',
        'folderById',
        'nextMessageId',
        'previousMessageId',
      ]),
      toggleMessageFlaggedDisplayValue() {
        return this.activeMessage.flagged
          ? this.$gettext('Unflag message')
          : this.$gettext('Flag message');
      },
      moveText() {
        return this.$gettext('Move');
      },
      trashText() {
        return this.$gettext('Trash');
      },
      moveToTrashText() {
        return this.$gettext('Move message to Trash');
      },
      deleteText() {
        return this.$gettext('Delete');
      },
      deleteMessageText() {
        return this.$gettext('Delete message');
      },
      deleteWarningText() {
        return this.$gettext(
          'The selected message will be permanently deleted. It is not possible to undo this action.'
        );
      },
      displayFormats() {
        return {
          date: getDateFormat(this.preferences.date_format),
          time: getTimeFormat(this.preferences.time_format),
        };
      },
      toggleFlagText() {
        return this.$gettext('Flag');
      },
      spamText() {
        return this.$gettext('Spam');
      },
      notSpamText() {
        return this.$gettext('Not spam');
      },
      markAsUnreadText() {
        return this.$gettext('Mark as unread');
      },
      forwardText() {
        return this.$gettext('Forward');
      },
      replyToAllText() {
        return this.$gettext('Reply all');
      },
      replyText() {
        return this.$gettext('Reply');
      },
      markAsSpamButtonText() {
        return this.$gettext('Mark message as spam');
      },
      markAsNotSpamButtonText() {
        return this.$gettext('Mark message as not spam');
      },
      nextMessageText() {
        return this.$gettext('Older');
      },
      previousMessageText() {
        return this.$gettext('Newer');
      },
      messageSourceUrl() {
        return endpoints.MESSAGE_SOURCE_ENDPOINT(this.activeMessage.id);
      },
      downloadAllFilesUrl() {
        let fileName;
        if (typeof this.activeMessage.subject === 'string') {
          fileName = this.activeMessage.subject
            .replace(/[\W_]+/g, '')
            .toLowerCase();
        }

        return endpoints.DOWNLOAD_FILES_ENDPOINT(
          this.activeMessage.id,
          fileName || this.$gettext('attachments')
        );
      },
      activeMessageNumberInList() {
        return (
          this.$store.getters['pagination/pageStart'] + this.activeMessageIndex
        );
      },
      isSpamMessage() {
        const folder = this.folderById(this.activeMessage.folder_id);
        return folder && folder.type === 'junk';
      },
      erroneousSignatures() {
        return this.activeMessage.pgp?.signatures.filter(
          (signature) =>
            signature.status !== PGP_SIGNATURE_OK ||
            signature.matches_sender === false ||
            signature.weak_algorithm === true
        );
      },
      moreText() {
        return this.$gettext('More');
      },
    },
    watch: {
      $route(to) {
        this.resetState();
        this.setActiveMessage({ id: to.params.message || to.query.message });
        this.readMessage({ id: this.activeMessageId })
          .then(({ show_external_images }) => {
            this.state = { loading: false };
            this.showExternalImages = Boolean(show_external_images);
          })
          .catch((error) => {
            this.state = { loading: false, error: ErrorType.MessageNotFound };
            throw error;
          });
      },
    },
    created() {
      this.setActiveMessage({
        id: this.$route.params.message || this.$route.query.message,
      });
      this.readMessage({ id: this.activeMessageId })
        .then(({ show_external_images }) => {
          this.state = { loading: false };
          this.showExternalImages = Boolean(show_external_images);
        })
        .catch((error) => {
          this.state = { loading: false, error: ErrorType.MessageNotFound };
          throw error;
        });

      this.$root.$on('setExternalLinkWarning', this.setExternalLinkWarning);
      // keyboard shortcuts
      Mousetrap.bind('r', this.replyClicked);
      Mousetrap.bind('g', this.replyAllClicked);
      Mousetrap.bind('f', this.forwardClicked);
      Mousetrap.bind(['d', 'del', 'backspace'], this.onTrashClicked);
      Mousetrap.bind(['right', 'l', ']'], () =>
        this.$root.$emit('readMessageInCurrentView', this.nextMessageId)
      );
      Mousetrap.bind(['left', 'h', '['], () =>
        this.$root.$emit('readMessageInCurrentView', this.previousMessageId)
      );
    },
    destroyed() {
      Mousetrap.unbind([
        'r',
        'g',
        'f',
        'd',
        'del',
        'backspace',
        'right',
        'l',
        ']',
        'left',
        'h',
        '[',
      ]);
      this.$root.$off('setExternalLinkWarning', this.setExternalLinkWarning);
    },
    methods: {
      ...mapActions([
        'changeMessagesReadStatus',
        'changeMessagesFlagStatus',
        'loadMessages',
        'markMessagesAsSpam',
        'markMessagesAsNotSpam',
        'moveMessagesToFolder',
        'moveMessagesToTrash',
        'readMessage',
        'deleteMessage',
        'setToastMessage',
        'setExternalLinkWarning',
        'reportPhishing',
      ]),
      ...mapMutations({
        setActiveMessage: SET_ACTIVE_MESSAGE,
      }),
      onPrintClick() {
        window.print();
      },
      onReportPhishingClicked: asyncAction('reportPhishing', function () {
        this.updateRoute();
        return this.reportPhishing({ messageId: this.activeMessage.id })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Phishing reported, message moved to spam'
              ),
            });
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not report your message as phishing'
              ),
            });
          })
          .finally(() => {
            this.loadMessages();
          });
      }),
      onMoveMessage: asyncAction('moving', function (folderId, modal) {
        modal.toggle();
        const targetFolder = this.folderById(folderId);
        const folderName = this.getFolderName(targetFolder);
        const activeMessageId = this.activeMessage.id;

        this.updateRoute();
        return this.moveMessagesToFolder({
          messagesIds: [activeMessageId],
          folderId,
        })
          .then(() => {
            const toastMessage = this.$gettextInterpolate(
              this.$gettext('Message moved to %{ folderName }'),
              { folderName },
              true
            );
            this.setToastMessage({ message: toastMessage });
            this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext('We could not move this message'),
            });
            this.loadMessages();
          });
      }),
      replyClicked() {
        this.$router.push({
          name: MAIL_COMPOSE,
          query: {
            ...this.$route.query,
            inReplyTo: this.activeMessage.id,
            fromFCC: this.activeMessage.is_fcc === true ? 'true' : 'false',
            encrypt:
              this.activeMessage.flags.is_encrypted === true ? 'true' : 'false',
            folder: this.activeFolder.id,
          },
        });
      },
      replyAllClicked() {
        this.$router.push({
          name: MAIL_COMPOSE,
          query: {
            ...this.$route.query,
            inReplyToAll: this.activeMessage.id,
            fromFCC: this.activeMessage.is_fcc === true ? 'true' : 'false',
            encrypt:
              this.activeMessage.flags.is_encrypted === true ? 'true' : 'false',
            folder: this.activeFolder.id,
          },
        });
      },
      forwardClicked() {
        this.$router.push({
          name: MAIL_COMPOSE,
          query: {
            ...this.$route.query,
            forwardFrom: this.activeMessage.id,
            fromFCC: this.activeMessage.is_fcc === true ? 'true' : 'false',
            encrypt:
              this.activeMessage.flags.is_encrypted === true ? 'true' : 'false',
            folder: this.activeFolder.id,
          },
        });
      },
      markAsSpamClicked: asyncAction('markingAsSpam', function () {
        const activeMessageId = this.activeMessage.id;
        this.updateRoute();
        return this.markMessagesAsSpam({ messagesIds: [activeMessageId] })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Message marked as spam'),
            });
            this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not mark your message as spam'
              ),
            });
            this.loadMessages();
          });
      }),
      markAsNotSpamClicked: asyncAction('markingAsNotSpam', function () {
        const activeMessageId = this.activeMessage.id;
        this.updateRoute();
        return this.markMessagesAsNotSpam({ messagesIds: [activeMessageId] })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Message marked as not spam'),
            });
            this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not mark your message as not spam'
              ),
            });
            this.loadMessages();
          });
      }),
      markAsUnreadClick: asyncAction('markingAsUnread', function () {
        return this.changeMessagesReadStatus({
          messagesIds: [this.activeMessage.id],
          unread: true,
        })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Message marked as unread'),
            });
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not mark your message as unread'
              ),
            });
            this.loadMessages();
          });
      }),
      toggleMessageFlaggedClicked: asyncAction('flagging', function () {
        return this.changeMessagesFlagStatus({
          messagesIds: [this.activeMessage.id],
          flagged: !this.activeMessage.flagged,
        }).catch(() => {
          const flagStatus = this.$gettext(
            this.activeMessage.flagged ? 'unflag' : 'flag'
          );
          const translatedText = this.$gettext(
            'Sorry, we could not %{ flagStatus } this message'
          );
          this.setToastMessage({
            message: this.$gettextInterpolate(translatedText, { flagStatus }),
          });
          this.loadMessages();
        });
      }),
      onDeleteClicked: asyncAction('deleting', function () {
        const activeMessageId = this.activeMessage.id;
        this.updateRoute();
        return this.deleteMessage({ messageId: activeMessageId })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Message has been deleted'),
            });
            this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext('Sorry, we could not delete your message'),
            });
            this.loadMessages();
          });
      }),
      onTrashClicked: asyncAction('trashing', function () {
        const activeMessageId = this.activeMessage.id;
        this.updateRoute();
        return this.moveMessagesToTrash({ messagesIds: [activeMessageId] })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Message moved to Trash'),
            });
            this.loadMessages();
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext('Sorry, we could not trash your message'),
            });
            this.loadMessages();
          });
      }),
      onMailToClicked(argurments) {
        const [emailAddress, subject] = argurments;
        this.$router.push({
          name: MAIL_COMPOSE,
          query: { mailTo: emailAddress, subject: subject },
        });
      },
      onClickShowExternalImages() {
        this.showExternalImages = true;
      },
      updateRoute() {
        if (this.$route.name === MAIL_MESSAGE) {
          const { message, ...query } = this.$route.query;
          this.$router.replace({ name: MAIL_FOLDER, query });
        } else if (this.$route.name === MAIL_FOLDER) {
          const { message, ...query } = this.$route.query;
          if (message) {
            this.$router.replace({ ...this.$route, query });
          }
        } else if (this.$route.name === MAIL_SEARCH) {
          // Change the id of the message but keep the folder search context.
          const { message, ...query } = this.$route.query;
          const originalFolder = this.$route.params.folder;
          this.$router.replace({
            name: MAIL_SEARCH,
            params: { folder: originalFolder, message: this.activeMessage.id },
            query,
          });
        }
      },
      /**
       * This function needs to initialize the state of the component to that initialized by the data() function.
       * XXX If you change this function, you probably need to change the data() function at the beginning of the
       * component.
       */
      resetState() {
        this.state = { loading: true };
        this.showExternalImages = false;
      },
    },
  };
</script>

<style src="./MessageWrapper.scss" lang="scss"></style>
