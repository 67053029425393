






















  import Vue from 'vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Modal from '@/components/Modal.vue';

  export default Vue.extend({
    name: 'ReportPhishingConfirmationModal',
    components: {
      Modal,
      ModalConfirmActions,
    },
    computed: {
      okButtonText(): string {
        return this.$gettext('Report');
      },
    },
  });
