












































  import Vue from 'vue';
  import Sidebar from '@/wrappers/SidebarWrapper/SidebarWrapper';
  import { mapGetters, mapActions, mapState } from 'vuex';
  import Mousetrap from 'mousetrap';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import NestedFoldersItem from '@/components/NestedFoldersItem/NestedFoldersItem.vue';
  import {
    MAIL,
    MAIL_FOLDER,
    SETTINGS_MAIL_FOLDERS,
  } from '@/router/named-routes';
  import { Location } from 'vue-router';
  import { AxiosError } from 'axios';
  import SidebarItem from '@/components/SidebarItem/SidebarItem.vue';

  export default Vue.extend({
    components: {
      NestedFoldersItem,
      Sidebar,
      SidebarItem,
    },
    mixins: [asyncActionsMixin, folderUtilsMixin],
    data() {
      return {
        loading: true,
        error: false,
      };
    },
    computed: {
      ...mapState('authentication', ['user']),
      ...mapGetters(['activeFolderPath', 'folderTree', 'sidebarIsOpen']),
      manageFoldersText(): string {
        return this.$gettext('Manage folders');
      },
      mailSettingsRoute(): Location {
        return { name: SETTINGS_MAIL_FOLDERS };
      },
    },
    created(this: any) {
      Mousetrap.bind('1', () => this.$router.push({ name: MAIL }));
      return this.loadFolders()
        .then(() => {
          this.loading = false;
          this.error = false;
          this.resetLoadingFoldersError();
        })
        .catch((error: AxiosError) => {
          this.loading = false;
          this.error = true;
          this.setLoadingFoldersError({ error });
          throw error;
        });
    },
    destroyed() {
      Mousetrap.unbind('1');
    },
    methods: {
      ...mapActions([
        'loadFolders',
        'loadMessages',
        'setToastMessage',
        'closeMobileSidebar',
        'setLoadingFoldersError',
        'resetLoadingFoldersError',
      ]),
      reloadMessages: asyncAction(
        'reloadingMessages',
        function (this: any, folderName: String) {
          return this.loadMessages().then(() => {
            this.closeMobileSidebar();
            this.setToastMessage({
              message: this.$gettextInterpolate(
                this.$gettext('%{ folder } is updated'),
                {
                  folder: folderName,
                }
              ),
            });
          });
        }
      ),
      getFolderRoute(folder: Folder) {
        return {
          name: MAIL_FOLDER,
          params: { folder: folder.id },
        };
      },
      folderItemClicked(this: any, folder: Folder) {
        // Ignore click on same item while reloading
        if (this.isBusy.reloadingMessages) {
          return;
        }
        // If reading a message clicking on active folder should navigate instead of reload
        const isMessageRoute = this.$route.params['message'] !== undefined;

        if (folder.id === this.$route.params.folder && !isMessageRoute) {
          this.reloadMessages(this.getFolderName(folder));
        } else {
          this.$router.push(this.getFolderRoute(folder));
        }
      },
    },
  });
