<template>
  <div class="message panel__section">
    <div
      class="message__actions action-bar panel__section-item panel__section-item--no-padding"
    >
      <slot name="actions" />
    </div>

    <div class="message__header panel__section-item">
      <time class="message__abs-date">{{ absoluteDate }}</time>

      <div class="message__info">
        <div class="message__primary-info">
          <div class="message__sender">
            <dl class="message__addresses">
              <dt><translate>From</translate>:</dt>
              <dd class="message__badges">
                <RecipientBadge
                  v-test:from
                  :show-badge-dropdown="showBadgeDropdown"
                  :value="message.from"
                  :official="message.official"
                />
              </dd>
            </dl>

            <Dropdown
              v-if="attachmentCount"
              class="message__attachments dropdown--align-left"
            >
              <template v-slot:button="dropdown">
                <button
                  type="button"
                  class="message__attachment"
                  v-test:messageAttachments
                  :aria-label="`${attachmentCount} ${attachmentText}`"
                  :title="`${attachmentCount} ${attachmentText}`"
                  @click="dropdown.toggle"
                >
                  <Icon symbol="attach" />
                  <span>{{ attachmentCount }}</span>
                  <span class="message__attachment-text" role="presentation">{{
                    attachmentText
                  }}</span>
                  <Icon symbol="arrow-down" class="message__attachment-arrow" />
                </button>
              </template>

              <template v-slot:content="dropdown">
                <ul class="link-list">
                  <li v-if="attachments.length > 1 && attachmentsDownloadLink">
                    <DownloadLink
                      :url="attachmentsDownloadLink"
                      class="link-list__item message__download-all-text"
                      @done="dropdown.toggle"
                    >
                      <Icon symbol="download" />
                      <translate>Download all</translate>
                    </DownloadLink>
                  </li>
                  <li
                    v-for="(file, index) in attachments"
                    :key="file.id"
                    :class="{ separated: index === 0 }"
                  >
                    <DownloadLink
                      class="link-list__item"
                      :url="file.url"
                      :filename="file.filename"
                      @done="dropdown.toggle"
                    >
                      <Icon symbol="file" />
                      {{ file.filename | truncateMiddle(20) }}
                      <span class="message__attachment-filesize">
                        ({{ file.size | formatDataSize }})</span
                      >
                    </DownloadLink>
                  </li>
                </ul>
              </template>
            </Dropdown>
          </div>
          <h1 class="message__subject" v-test:subject>{{ message.subject }}</h1>
          <dl class="message__addresses">
            <dt><translate>To</translate>:</dt>
            <dd class="message__badges">
              <RecipientBadge
                v-test:to
                v-for="recipient in message.to"
                :show-badge-dropdown="showBadgeDropdown"
                :hide-email="true"
                :key="recipient.email"
                :value="recipient"
              />
            </dd>
          </dl>
          <dl class="message__addresses" v-if="message.cc.length">
            <dt><translate>Cc</translate>:</dt>
            <dd class="message__badges">
              <RecipientBadge
                v-test:cc
                v-for="(recipient, index) in message.cc"
                :show-badge-dropdown="showBadgeDropdown"
                :hide-email="true"
                :key="index"
                :value="recipient"
              />
            </dd>
          </dl>
          <dl class="message__addresses" v-if="message.bcc.length">
            <dt><translate>Bcc</translate>:</dt>
            <dd class="message__badges">
              <RecipientBadge
                v-test:bcc
                v-for="(recipient, index) in message.bcc"
                :show-badge-dropdown="showBadgeDropdown"
                :hide-email="true"
                :key="index"
                :value="recipient"
              />
            </dd>
          </dl>
          <dl class="message__addresses" v-if="message.reply_to.length">
            <dt><translate>Reply-To</translate>:</dt>
            <dd class="message__badges">
              <RecipientBadge
                v-test:replyTo
                v-for="(recipient, index) in message.reply_to"
                :show-badge-dropdown="showBadgeDropdown"
                :key="index"
                :hide-email="true"
                :value="recipient"
              />
            </dd>
          </dl>
        </div>

        <div class="message__secondary-info">
          <time class="message__rel-date" v-test:relDate>{{
            relativeDate
          }}</time>
        </div>
      </div>
      <slot name="top" :message-decryption-status="decryptionStatus" />
    </div>

    <transition name="collapsible" :duration="{ enter: 0 }">
      <div
        class="message__external-images-notice"
        v-if="hasExternalImages && !showExternalImages"
      >
        <translate class="message-body__external-image-text">
          External images are hidden for privacy and security reasons
        </translate>
        <button
          type="button"
          class="message-body__show-external-images button"
          @click="$emit('showExternalImages')"
        >
          <translate>Show images</translate>
        </button>
      </div>
    </transition>
    <DecryptMessage
      v-if="encryptedMessage"
      :message-id="message.id"
      :message-encryption-status="decryptionStatus"
      @decryptionStatus="setDecryptionStatus"
    />
    <MessageBody
      v-else
      :body="message.body"
      :attachments="message.files"
      :element-container-class="message.css_prefix"
      :show-external-images="showExternalImages"
      :external-link-warning="externalLinkWarning"
      @hasExternalImages="hasExternalImages = $event"
      @mailToClicked="$emit('mailToClicked', arguments)"
      ref="messageBody"
      :dark-mode="darkMode"
    />
  </div>
</template>

<script>
  import Dropdown from '@/components/Dropdown/Dropdown';
  import DecryptMessage from '@/components/DecryptMessage/DecryptMessage';
  import Icon from '@/components/Icon/Icon';
  import RecipientBadge from '@/components/RecipientBadge/RecipientBadge';
  import MessageBody from '@/components/MessageBody/MessageBody';
  import formatDataSize from '@/lib/formatDataSize';
  import truncateMiddle from '@/lib/truncateMiddle';
  import { mixin as timeMixin } from '@/lib/time';
  import { getDateFormat, getTimeFormat } from '@/lib/dateTimeFormats';
  import DownloadLink from '@/components/DownloadLink';

  const PGP_MESSAGE_DECRYPTED_STATUS = 0;

  export default {
    name: 'Message',
    components: {
      DecryptMessage,
      Dropdown,
      DownloadLink,
      Icon,
      RecipientBadge,
      MessageBody,
    },
    filters: {
      formatDataSize,
      truncateMiddle,
    },
    mixins: [timeMixin],
    props: {
      message: {
        type: Object,
        required: true,
      },
      attachmentsDownloadLink: {
        type: String,
        default: null,
      },
      showExternalImages: {
        type: Boolean,
        default: false,
      },
      externalLinkWarning: {
        type: Boolean,
        default: false,
      },
      showBadgeDropdown: {
        type: Boolean,
        default: false,
      },
      displayFormats: {
        type: Object,
        required: false,
        default: () => ({
          date: '',
          time: '',
        }),
      },
      darkMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        hasExternalImages: false,
        decryptionStatus: this.message.pgp.encryption.status,
      };
    },
    computed: {
      absoluteDate() {
        return this.formatDate(
          this.message.date,
          'dddd DD MMMM YYYY [at] HH:mm:ss'
        );
      },
      attachments() {
        return (this.message.files || []).filter((file) => !file.inline);
      },
      attachmentCount() {
        return this.attachments.length;
      },
      attachmentText() {
        return this.$ngettext(
          'attachment',
          'attachments',
          this.attachmentCount
        );
      },
      encryptedMessage() {
        return (
          this.message.flags?.is_encrypted &&
          this.decryptionStatus !== PGP_MESSAGE_DECRYPTED_STATUS
        );
      },
      relativeDate() {
        const dateFormat = getDateFormat(this.displayFormats.date);
        const timeFormat =
          getTimeFormat(this.displayFormats.time) === '12h' ? 'h:mm a' : 'H:mm';
        const dateString = this.$gettextInterpolate(
          this.$gettext('%{ dateFormat } [at] %{ timeFormat }'),
          { dateFormat, timeFormat }
        );

        return this.fromNow(this.message.date, {
          sameDay: this.$gettextInterpolate(
            this.$gettext(`[Today at] %{ timeFormat }`),
            { timeFormat }
          ),
          lastWeek: dateString,
          lastDay: dateString,
          lastYear: dateString,
          sameElse: dateString,
        });
      },
    },
    methods: {
      setDecryptionStatus(error) {
        this.decryptionStatus = error;
      },
    },
  };
</script>

<style src="./Message.scss" lang="scss"></style>
