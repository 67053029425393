

























































  import Icon from '@/components/Icon/Icon.vue';
  import Vue, { PropType } from 'vue';
  import {
    PGP_BAD_SIGNATURE,
    PGP_SIGNATURE_EXPIRED,
    PGP_KEY_EXPIRED,
    PGP_KEY_REVOKED,
    PGP_NO_KEYS_AVAILABLE,
    PGP_KEY_NOT_AVAILABLE,
    PGP_DATA_CORRUPTED,
    PGP_GENERIC_ERROR,
  } from '@/api/error-codes';
  import { SETTINGS_PGP_KEYRING_KEY } from '@/router/named-routes';

  export default Vue.extend({
    name: 'MessageStatus',
    components: {
      Icon,
    },
    data() {
      return {
        SETTINGS_PGP_KEYRING_KEY,
      };
    },
    props: {
      signed: {
        type: Boolean,
        required: true,
      },
      encrypted: {
        type: Boolean,
        required: true,
      },
      erroneousSignatures: {
        type: Array as PropType<PGPSignatureInformation[]>,
        required: true,
      },
      messageDecryptionStatus: {
        type: Number,
        required: true,
      },
      pgpKeys: {
        type: Array as PropType<PGPKey[]>,
        required: false,
      },
    },
    computed: {
      statusText(): string {
        if (this.encrypted && this.signed) {
          return this.$gettext(
            'This email is <strong>PGP signed</strong> and <strong>PGP encrypted</strong>'
          );
        } else if (this.encrypted) {
          return this.$gettext('This email is <strong>PGP encrypted</strong>');
        } else if (this.signed) {
          return this.$gettext('This email is <strong>PGP signed</strong>');
        }
        return '';
      },
      keyIdLabelText(): string {
        return this.$gettext('key id:');
      },
      usingLabelText(): string {
        return this.$gettext('using');
      },
      senderDoesNotMatchError(): string {
        return this.$gettext(
          '<strong>Unexpected signer</strong>: the signer does not match the sender.'
        );
      },
      weakAlgorithmError(): string {
        return this.$gettext(
          '<strong>Weak signature</strong>: the signature uses a weak cryptographic algorithm.'
        );
      },
      messageDecryptionStatusText(): string | null {
        switch (this.messageDecryptionStatus) {
          case PGP_NO_KEYS_AVAILABLE:
          case PGP_KEY_NOT_AVAILABLE:
            return this.$gettext(
              'You do not have the required key. Please contact the sender.'
            );
          case PGP_DATA_CORRUPTED:
            return this.$gettext(
              'The data is corrupted. Please contact the sender.'
            );
          case PGP_GENERIC_ERROR:
            return this.$gettext(
              'An unknown error occurred. This email could not be decrypted.'
            );
          default:
            return null;
        }
      },
    },
    methods: {
      signatureStatusError(signature: PGPSignatureInformation) {
        switch (signature.status) {
          case PGP_BAD_SIGNATURE:
            return this.$gettext(
              '<strong>Invalid signature</strong>: the signature does not match the message.'
            );

          case PGP_SIGNATURE_EXPIRED:
            return this.$gettext(
              '<strong>Invalid signature</strong>: the signature has expired.'
            );

          case PGP_KEY_EXPIRED:
            return this.$gettext(
              '<strong>Invalid signature</strong>: the key of the signer has expired.'
            );

          case PGP_KEY_REVOKED:
            return this.$gettext(
              '<strong>Invalid signature</strong>: key of the signer has been revoked.'
            );

          case PGP_KEY_NOT_AVAILABLE:
            return this.$gettext(
              '<strong>Unverifiable signature</strong>: you do not have the public key of the signer.'
            );

          case PGP_GENERIC_ERROR:
            return this.$gettext(
              '<strong>Unverifiable signature</strong>: there was an unexpected error.'
            );

          default:
            return '';
        }
      },
    },
  });
