











































  import { mapActions } from 'vuex';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import Checkbox from '@/components/form/Checkbox.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import getErrorCode from '@/api/get-error-code';
  import {
    PGP_BAD_PASSPHRASE,
    PGP_NO_PASSPHRASE,
    PGP_NO_KEYS_AVAILABLE,
    PGP_KEY_NOT_AVAILABLE,
    PGP_DATA_CORRUPTED,
    PGP_GENERIC_ERROR,
    PGP_DECRYPTION_FAILED,
  } from '@/api/error-codes';
  import Vue from 'vue';
  import { AxiosError } from 'axios';

  export default Vue.extend({
    name: 'DecryptMessage',
    components: {
      PasswordField,
      Checkbox,
      LoadingButton,
    },
    mixins: [asyncActionsMixin],
    props: {
      messageId: {
        type: String,
        required: true,
      },
      messageEncryptionStatus: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        pgpPassphrase: '',
        rememberPgpPassphrase: false,
        decryptionStatus: null as number | null,
        validatePgpPassphrase: false,
      };
    },
    computed: {
      decryptText(): string {
        return this.$gettext('Decrypt');
      },
      rememberPgpPassphraseText(): string {
        return this.$gettext('Remember this PGP passphrase until I log out');
      },
      pgpPassPhraseText(): string {
        return this.$gettext(
          'Enter the PGP passphrase below to see the email.'
        );
      },
      decryptionStatusMessage(): string {
        switch (this.decryptionStatus) {
          case PGP_BAD_PASSPHRASE:
            return this.$gettext(
              'PGP key and passphrase do not match. Please try again.'
            );
          case PGP_DECRYPTION_FAILED:
            return this.$gettext(
              'Email could not be decrypted. Trying a different passphrase may help.'
            );
          default:
            return this.$gettext('PGP passphrase is required.');
        }
      },
      showPasswordFieldValidation(): boolean {
        return (
          this.decryptionStatus === PGP_BAD_PASSPHRASE ||
          this.decryptionStatus === PGP_DECRYPTION_FAILED
        );
      },
      unableToDecryptText(): string | null {
        switch (this.decryptionStatus) {
          case PGP_NO_KEYS_AVAILABLE:
          case PGP_KEY_NOT_AVAILABLE:
            return this.$gettext(
              'You do not have the required key. Please contact the sender.'
            );
          case PGP_DATA_CORRUPTED:
            return this.$gettext(
              'The data is corrupted. Please contact the sender.'
            );
          case PGP_GENERIC_ERROR:
            return this.$gettext(
              'An unknown error occurred. This email could not be decrypted.'
            );
          default:
            return null;
        }
      },
    },
    created() {
      this.decryptionStatus = this.messageEncryptionStatus;
    },
    watch: {
      pgpPassphrase() {
        this.decryptionStatus = null;
        this.validatePgpPassphrase = false;
      },
    },
    methods: {
      ...mapActions(['decryptMessage', 'setToastMessage']),
      onDecryptMessage: asyncAction(
        'decrypting',
        function (this: any, ev: Event) {
          this.validatePgpPassphrase = true;
          const target = ev.target as HTMLFormElement;
          if (!target.checkValidity()) {
            return false;
          }
          return this.decryptMessage({
            id: this.messageId,
            pgpPassphrase: this.pgpPassphrase,
            rememberPgpPassphrase: this.rememberPgpPassphrase,
          })
            .then((response: Message) => {
              this.$emit('decryptionStatus', response.pgp.encryption.status);
            })
            .catch((error: AxiosError) => {
              const errorCode = getErrorCode(error);
              this.decryptionStatus = errorCode;

              switch (errorCode) {
                // Emit these errors in order to update MessageStatus
                case PGP_NO_KEYS_AVAILABLE:
                case PGP_KEY_NOT_AVAILABLE:
                case PGP_DATA_CORRUPTED:
                case PGP_GENERIC_ERROR:
                case PGP_DECRYPTION_FAILED:
                case PGP_NO_PASSPHRASE:
                case PGP_BAD_PASSPHRASE:
                  this.$emit('decryptionStatus', errorCode);
                  break;
                // For any other unkown error inform the user and throw it
                default:
                  this.setToastMessage({
                    message: this.$gettext(
                      'Something went wrong. Please try again later.'
                    ),
                  });
                  throw error;
              }
            });
        }
      ),
    },
  });
