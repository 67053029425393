<template>
  <div class="container" v-test:mailPage>
    <router-view name="appHeader" class="mail__header" />

    <MailSidebar />

    <SplitPane
      v-if="readingPaneIsActive"
      local-storage-key-prefix="mail-layout"
      :vertical="verticalSplitLayout"
      tag="main"
      class="main-content"
      v-test:mainContent
    >
      <template v-slot:first>
        <transition name="fade" mode="out-in">
          <router-view name="main" :key="mainViewKey" />
        </transition>
      </template>

      <template v-slot:second>
        <transition name="fade" mode="out-in">
          <router-view
            name="pane"
            :key="$route.fullPath"
            v-if="$route.query.message"
          />
          <PassiveMessage v-else />
        </transition>
      </template>
    </SplitPane>

    <main class="main-content" v-else v-test:mainContent>
      <transition name="fade" mode="out-in">
        <router-view name="main" :key="mainViewKey" />
      </transition>
    </main>
  </div>
</template>

<script>
  import Mousetrap from 'mousetrap';
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import MailSidebar from '@/components/MailSidebar/MailSidebar';
  import { SET_PAGE } from '@/store/pagination';
  import {
    MAIL_COMPOSE,
    MAIL_FOLDER,
    MAIL_MESSAGE,
    MAIL_SEARCH,
  } from '@/router/named-routes';
  import { SPLIT_NONE, SPLIT_VERTICAL } from '@/store/layout/layout';
  import PassiveMessage from '@/components/PassiveMessage/PassiveMessage';
  import SplitPane from '@/components/SplitPane/SplitPane';
  import { getItem } from '@/lib/localStorage';
  import { UI_MEDIAQUERIES } from '@/ui-mediaqueries';
  const desktopMediaQuery = window.matchMedia(
    UI_MEDIAQUERIES.SIDEBAR_NOT_COLLAPSED
  );
  export default {
    name: 'Mail',
    components: {
      MailSidebar,
      PassiveMessage,
      SplitPane,
    },
    data() {
      return {
        isLargeScreen: desktopMediaQuery.matches,
      };
    },
    computed: {
      ...mapState({
        layoutSplitState: (state) => state.layout.activeSplit,
      }),
      ...mapGetters(['activeMessagesCount', 'loadingFoldersError']),
      ...mapState({
        activeMessagesIds: (state) => state.messages.activeMessagesIds,
      }),
      readingPaneIsActive() {
        return (
          (this.$route.name === MAIL_FOLDER ||
            this.$route.name === MAIL_SEARCH) &&
          !this.defaultLayout
        );
      },
      isComposeRoute() {
        return this.$route.name === MAIL_COMPOSE;
      },
      defaultLayout() {
        return (
          this.layoutSplitState === SPLIT_NONE ||
          this.activeMessagesCount === 0 ||
          this.loadingFoldersError
        );
      },
      verticalSplitLayout() {
        return this.layoutSplitState === SPLIT_VERTICAL;
      },
      mainViewKey() {
        if (this.isComposeRoute) {
          return this.$route.path;
        }

        return this.fullPathWithoutMessage(this.$route);
      },
    },
    watch: {
      $route(to, from) {
        if (
          this.fullPathWithoutMessage(to) !== this.fullPathWithoutMessage(from)
        ) {
          this.updateState();
        }
      },
    },
    created() {
      this.updateState();
      this.$root.$on('readMessageInCurrentView', this.readMessageInCurrentView);
      Mousetrap.bind(['c', 'm'], () =>
        this.$router.push({ name: MAIL_COMPOSE })
      );
      desktopMediaQuery.addListener(this.handleResize);
    },
    destroy() {
      this.$root.$off(
        'readMessageInCurrentView',
        this.readMessageInCurrentView
      );
      Mousetrap.unbind(['c', 'm']);
      desktopMediaQuery.removeListener(this.handleResize);
    },
    methods: {
      ...mapActions([
        'setActiveFolder',
        'setActiveSearchQuery',
        'setSortOrder',
      ]),
      ...mapActions('layout', ['setDefaultSplit', 'setLayoutSplit']),
      ...mapActions('filters', ['setFilters']),
      ...mapMutations({
        setPage: `pagination/${SET_PAGE}`,
      }),
      updateState() {
        const query = this.$route.query.query;
        const folderId = this.$route.params.folder || this.$route.query.folder;
        if (query) {
          this.setActiveSearchQuery({ query });
        } else if (folderId) {
          this.setActiveFolder({ folderId });
        }

        const order = this.$route.query.sortOrder;
        if (order) {
          this.setSortOrder({ order });
        }

        const filters = this.$route.query.filter;
        this.setFilters({
          filters: (Array.isArray(filters) ? filters : [filters]).filter(
            Boolean
          ),
        });

        this.setPage(parseInt(this.$route.query.page) || 1);
      },
      readMessageInCurrentView(messageId) {
        if (!messageId) {
          return;
        }

        if (
          this.$route.name === MAIL_MESSAGE &&
          this.$route.params.message !== messageId
        ) {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, message: messageId },
          });
        } else if (
          (this.$route.name === MAIL_FOLDER ||
            this.$route.name === MAIL_SEARCH) &&
          this.$route.query.message !== messageId
        ) {
          this.$router.push({
            ...this.$route,
            query: { ...this.$route.query, message: messageId },
          });
        }
      },
      fullPathWithoutMessage(route) {
        const { message, ...query } = route.query;
        const newRoute = this.$router.resolve({ ...route, query });
        return (
          (newRoute && newRoute.route && newRoute.route.fullPath) ||
          route.fullPath
        );
      },
      async handleResize(ev) {
        if (ev.matches) {
          const layoutSplit = await getItem('sm-layout-split');
          if (
            layoutSplit !== null ||
            (undefined && layoutSplit !== this.layoutSplitState)
          ) {
            // Verify if layoutSplit from localStorage is not null/undefined
            this.setLayoutSplit({ layoutSplit });
          }
        } else {
          this.setDefaultSplit();
        }
      },
    },
  };
</script>
