<template>
  <section
    class="message-list"
    v-test:messageList
    :class="{ 'message-list--split': isVerticalSplit }"
  >
    <h1>{{ debouncedMessageLoading }}</h1>
    <ErrorView v-if="error" :error-type="error" />

    <SkeletonList
      v-else-if="showSkeletonList"
      :item-count="skeletonUiItemCount"
    />

    <div class="message-list__content" v-else>
      <template v-for="group in groupedMessages">
        <h4
          class="panel__title panel__title--list"
          v-if="group.title && group.messages.length"
          :key="group.title"
        >
          {{ group.title }}
        </h4>
        <transition-group
          tag="ul"
          name="list"
          class="message-list__items"
          v-if="group.messages.length"
          :key="`${group.title}-list`"
        >
          <MessageListItem
            @click="onItemClicked"
            @shift-select="onShiftSelect"
            v-for="message in group.messages"
            :message="message"
            :email="messageSender(message)"
            :key="message.id"
            :show-folder="showFolders"
            :tooltip="messageTooltip(message)"
            :date-format="dateFormat"
            :time-format="timeFormat"
          />
        </transition-group>
      </template>
      <template v-if="activeMessagesCount === 0">
        <EmptyFolder
          :folder-id="activeFolderId"
          :active-search-query="activeSearchQuery"
          :active-filters="Boolean(activeFilters.length)"
        />
      </template>
    </div>
  </section>
</template>

<script>
  import { IMAP_FOLDER_DOES_NOT_EXIST } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import EmptyFolder from '@/components/EmptyFolder/EmptyFolder';
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView';
  import MessageListItem from '@/components/MessageListItem/MessageListItem';
  import SkeletonList from '@/components/SkeletonList/SkeletonList';
  import { getDateFormat, getTimeFormat } from '@/lib/dateTimeFormats';
  import selectItemRange from '@/lib/selectItemRange';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import groupMixin from '@/mixins/group-mixin';
  import { SPLIT_NONE } from '@/store/layout/layout';
  import {
    FILTER_ATTACHMENT,
    FILTER_ENCRYPTED,
    FILTER_FLAGGED,
    FILTER_UNREAD,
  } from '@/store/mail/modules/filters';
  import {
    DATE_ASCENDING,
    DATE_DESCENDING,
    RECIPIENT_ASCENDING,
    RECIPIENT_DESCENDING,
    SENDER_ASCENDING,
    SENDER_DESCENDING,
  } from '@/store/sorting/sorting';
  import Mousetrap from 'mousetrap';
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    components: {
      EmptyFolder,
      ErrorView,
      MessageListItem,
      SkeletonList,
    },
    mixins: [groupMixin, folderUtilsMixin],
    data() {
      return {
        loadingErrorCode: null,
        loading: true,
      };
    },
    computed: {
      ...mapState({
        activeFolderId: (state) => state.folders.activeFolderId,
        activeMessagesIds: (state) => state.messages.activeMessagesIds,
        selectedMessagesIds: (state) => state.messages.selectedMessagesIds,
        activeSearchQuery: (state) => state.search.activeSearchQuery,
        sortOrderState: (state) => state.sorting.sortOrder,
        activeFilters: (state) => state.filters.activeFilters,
        layoutSplitState: (state) => state.layout.activeSplit,
        debouncedMessageLoading: (state) =>
          state.messages.debouncedMessageLoading,
      }),
      ...mapGetters([
        'activeFolder',
        'messageById',
        'nextMessageId',
        'pagination/currentPageSize',
        'previousMessageId',
        'loadingFoldersError',
        'loadingMessages',
      ]),
      ...mapGetters('layout', ['densityOption', 'isVerticalSplit']),
      ...mapGetters('authentication', ['user']),
      showSkeletonList() {
        /* Show loading animation when this component is loading or the messages are being moved/deleted (this.loadingMessages)
           and there is no active messages to display (eg. move all emails visible on one page to a different folder) */
        return (
          this.loading ||
          (this.loadingMessages && this.activeMessagesCount === 0) ||
          this.debouncedMessageLoading
        );
      },
      activeMessages() {
        return this.activeMessagesIds.map(this.messageById);
      },
      groupedMessages() {
        /* Group messages only if density chosen is default and messages are sorted alphabetically */
        const nameSortingOptions = [
          SENDER_ASCENDING,
          SENDER_DESCENDING,
          RECIPIENT_ASCENDING,
          RECIPIENT_DESCENDING,
        ];
        if (
          this.densityOption.groupItems &&
          nameSortingOptions.includes(this.sortOrder)
        ) {
          return Object.entries(
            this.groupByFirstCharacter(
              this.activeMessages,
              '#',
              this.messageGroupBy
            )
          ).map(([title, messages]) => ({ title, messages }));
        }

        return [{ messages: this.activeMessages }];
      },
      showFolders() {
        return Boolean(this.activeSearchQuery);
      },
      outbound() {
        return ['sent', 'drafts'].includes(this.activeFolderType);
      },
      activeFolderType() {
        // activeFolder could be undefined when this component is first rendered because the folder network request is not done yet.
        return this.activeFolder?.type;
      },
      skeletonUiItemCount() {
        return this['pagination/currentPageSize'] || 12;
      },
      error() {
        if (this.loadingFoldersError) {
          return ErrorType.LoadingFoldersError;
        } else if (this.loadingErrorCode) {
          return this.loadingErrorCode === IMAP_FOLDER_DOES_NOT_EXIST
            ? ErrorType.FolderNotFound
            : ErrorType.LoadingMessagesError;
        } else {
          return null;
        }
      },
      activeMessagesCount() {
        return this.activeMessages.length;
      },
      sortOrder: {
        get() {
          return this.sortOrderState;
        },
        set(order) {
          this.setSortOrder({ order });
          this.$router.replace({
            ...this.$route,
            query: { ...this.$route.query, sortOrder: order },
          });
        },
      },
      dateFormat() {
        const user = this.user;
        const format = user && user.preferences && user.preferences.date_format;

        return getDateFormat(format || '');
      },
      timeFormat() {
        const user = this.user;
        const format = user && user.preferences && user.preferences.time_format;

        return getTimeFormat(format || '');
      },
    },
    async created() {
      try {
        await this.loadMessages();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.loadingErrorCode = getErrorCode(error) || 'Unknown error';
        throw error;
      }
    },
    mounted() {
      // keyboard shortcuts
      const guardNoSplit = (fn) => () =>
        this.layoutSplitState !== SPLIT_NONE && fn();
      Mousetrap.bind(
        ['down', 'j'],
        guardNoSplit(() =>
          this.$root.$emit('readMessageInCurrentView', this.nextMessageId)
        )
      );
      Mousetrap.bind(
        ['up', 'k'],
        guardNoSplit(() =>
          this.$root.$emit('readMessageInCurrentView', this.previousMessageId)
        )
      );
    },
    beforeDestroy() {
      Mousetrap.unbind(['up', 'down', 'j', 'k']);
    },
    methods: {
      ...mapActions(['setMessageSelection', 'setSortOrder', 'loadMessages']),
      ...mapActions('filters', ['setFilter']),
      onItemClicked(route) {
        if (
          this.activeFolderType === 'drafts' ||
          this.layoutSplitState === SPLIT_NONE
        ) {
          this.$router.push(route.location);
        } else if (
          this.$route.query.message !== route.location.params.message
        ) {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              message: route.location.params.message,
            },
          });
        }
      },
      messageSender(message) {
        if (this.outbound) {
          // to, cc and bcc joined
          const recipients = message.to
            .concat(message.cc, message.bcc)
            .map((recipient) => recipient.name || recipient.email)
            .join(', ');
          return `${this.$gettext('To:')} ${recipients}`;
        } else {
          return message.from.name || message.from.email;
        }
      },
      messageTooltip(message) {
        if (this.outbound) {
          const recipients = message.to
            .concat(message.cc, message.bcc)
            .map((recipient) => recipient.name || recipient.email);
          return recipients[0] || '';
        }
        return message.from.email;
      },
      messageGroupBy(message) {
        if (this.outbound) {
          // to, cc and bcc joined
          const recipients = message.to
            .concat(message.cc, message.bcc)
            .map((recipient) => recipient.name || recipient.email);
          return recipients[0] || '';
        } else {
          return message?.from.name || message?.from.email;
        }
      },
      onShiftSelect({ message, selected }) {
        const messageIds = selectItemRange(
          this.activeMessagesIds,
          this.selectedMessagesIds,
          message.id,
          selected
        );
        this.setMessageSelection({ ids: messageIds });
      },
      translateSortOption(option) {
        switch (option) {
          case DATE_ASCENDING:
            return this.$gettext('Date ascending');

          case DATE_DESCENDING:
            return this.$gettext('Date descending (default)');

          case SENDER_ASCENDING:
          case RECIPIENT_ASCENDING:
            return this.$gettext('Name ascending');

          case SENDER_DESCENDING:
          case RECIPIENT_DESCENDING:
            return this.$gettext('Name descending');
        }
      },
      translateFilterOption(option) {
        switch (option) {
          case FILTER_UNREAD:
            return this.$gettext('Unread');
          case FILTER_FLAGGED:
            return this.$gettext('Flagged');
          case FILTER_ATTACHMENT:
            return this.$gettext('Attachment');
          case FILTER_ENCRYPTED:
            return this.$gettext('Encrypted');
        }
      },
      async toggleFilter(filter, active) {
        const activeFilters = await this.setFilter({ filter, active });
        const { page, ...query } = this.$route.query;
        this.$router.replace({
          ...this.$route,
          query: { ...query, filter: activeFilters },
        });
      },
      isActiveFilter(filter) {
        return this.activeFilters.includes(filter);
      },
    },
  };
</script>

<style src="./MessageList.scss" lang="scss"></style>
